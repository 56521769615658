import {Controller} from 'stimulus';
import {Modal} from 'bootstrap';

export default class extends Controller {
    static targets = ['modal', 'modali', 'tutorial']

    connect() {

    }

    modalTargetConnected(element) {
        let modal = new Modal(element)
        modal.show()
    }

    modaliTargetConnected(element) {
        let modali = new Modal(element)
    }

    tutorialTargetConnected(element) {
        setTimeout(() => {
            this.getTutorialStatus()
        }, 500)
    }

    getTutorialStatus() {
        // call endpoint to get user's tutorial status
        fetch('/tutorial', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({tutorial: true})
        }).then()
    }

    setTutorialTrue(element) {
        // call endpoint to update user's tutorial status
        fetch('/tutorial', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({tutorial: true})
        }).then()
    }

}